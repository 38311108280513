import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { rhythm } from '../utils/typography';

const CreatedDateWrapper = styled.small`
	display: block;
	margin-bottom: ${rhythm(1)};
	margin-top: ${rhythm(-1)};
`;

const StyledHorizontalRule = styled.hr`
	margin-bottom: ${rhythm(1)};
`;

const RelativePosts = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	list-style: none;
	padding: 0;
`;

export default class BlogPostContentfulTemplate extends React.Component {
	render() {
		const { location, data, pageContext } = this.props;
		const post = data.contentfulBlogPost;
		const siteTitle = data.site.siteMetadata.title;
		const { previous, next } = pageContext;

		return (
			<Layout location={location} title={siteTitle}>
				<SEO title={post.title} description={post.subtitle} />

				<h1>{post.title}</h1>

				<CreatedDateWrapper>{post.createdDate}</CreatedDateWrapper>

				<div
					dangerouslySetInnerHTML={{
						__html: post.content.childMarkdownRemark.html
					}}
				/>

				<StyledHorizontalRule />

				<Bio />

				<RelativePosts>
					<li>
						{previous && (
							<Link to={`/${previous.slug}`} rel="prev">
								← {previous.title}
							</Link>
						)}
					</li>
					<li>
						{next && (
							<Link to={`/${next.slug}`} rel="next">
								{next.title} →
							</Link>
						)}
					</li>
				</RelativePosts>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				author
			}
		}
		contentfulBlogPost(slug: { eq: $slug }) {
			id
			title
			createdDate
			content {
				childMarkdownRemark {
					html
					excerpt(pruneLength: 160)
				}
			}
			description {
				childMarkdownRemark {
					html
				}
			}
		}
	}
`;
